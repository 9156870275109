@use 'sass:map';
@use '@angular/material' as mat-mdc;

[dir=rtl] .mat-mdc-slide-toggle-bar, .mat-mdc-slide-toggle-label-before .mat-mdc-slide-toggle-bar {
  margin-left: auto !important;
}

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-slide-toggle-bar {
    background-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 200);
  }

  .mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar {
    background-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 200);
  }

  .mat-mdc-slide-toggle-thumb {
    background-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 400);
  }

  .mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
    background-color: #00BB2C;
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-slide-toggle-content {
    @include mat-mdc.m2-typography-level($typography-config, 'button');
    font-family: mat-mdc.m2-font-family($typography-config, 'button') !important;
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
