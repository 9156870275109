@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-button-toggle-checked {
    background-color: mat-mdc.m2-get-color-from-palette($primary-palette, 100) !important;
  }


}


@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  mat-nav-list.mat-mdc-nav-list .mat-mdc-list-item span.nav-button-content span {
    text-align: left;
    font-family: 'Open Sans Semibold';
    font-size: 16px;
    line-height: 22px;
  }


}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
