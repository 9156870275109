@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  mat-chip-set mat-basic-chip {
    background-color: white;
    border-radius: 16px;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 0.5rem;
    vertical-align: top;

    i.mat-chip-avatar {
      font-size: 16px;
    }

    .valid-icon i {
      font-size: inherit;
    }

    &:hover {
      background-color: #BDBDBD;
    }

    .mat-chip-avatar {
      margin: 0.5rem;
    }

    i {
      font-size: 16px;
    }
  }

}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
