// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat-mdc;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Fonts

@font-face {
  font-family: 'Open Sans Regular';
  src: url('assets/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans Semibold';
  src: url('assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('assets/fonts/OpenSans/OpenSans-Bold.ttf');
}


// import our custom theme
@import "./theme.scss";


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

//@include angular-material-theme($theming-material-components-theme);
//@include custom-components-theme($theming-material-components-theme);

// Override typography for all Angular Material, including mat-base-typography and all components.
//@include angular-material-typography($typography);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  background: #F8F8FA;
  font-family: "Open Sans Regular", "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color-400);
}

.pointer {
  cursor: pointer;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.loading-overlay {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 999999;
  background: inherit;
  box-shadow: 10px 10px white;
  //background-color: rgba(0,0,0,0.5);
}


.mat-mdc-dialog-container:has(.mat-datepicker-content) {
  padding: 0;
}

.cdk-overlay-container {
  z-index: 999999;
}

.user-menu {
  background: white;

  .user-menu-header {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    padding: 0.5rem;
  }

  .profile-image {
    width: 64px;
    height: 64px;
    border-radius: 50px;
  }

}

.error-snackbar {
  background: var(--warn-color);
  color: white;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  align-items: center;
}

.warning-snackbar {
  background: var(--warn-color);
  color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  padding: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.success-snackbar {
  background: var(--success-color);
  color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  padding: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.reconnect-snackbar {
  border-radius: 10px;
  background: white;
  color: black;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-color-200);
  box-shadow: 0 3px 6px #00000029;
  padding: 1rem;
  display: flex;
  align-items: center;

  .spinner {
    margin-left: auto;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  background-color: transparent !important;
  box-shadow: none !important;
}




