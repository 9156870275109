@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  table.mat-mdc-table {
    border: 1px solid #C6CBDD;
    border-radius: 5px;
  }

  th.mat-mdc-header-cell {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: #C6CBDD;
  }

  th.mat-mdc-header-cell:first-of-type {
    border-bottom-left-radius: 5px;
  }

  th.mat-mdc-header-cell:last-of-type {
    border-bottom-right-radius: 5px;
  }

  .mat-mdc-row:nth-child(even) td.mat-mdc-cell {
    background-color: #FAFAFA;
  }

  .mat-mdc-row:nth-child(odd) td.mat-mdc-cell {
    background-color: white;
  }

  tr.mat-mdc-header-row {
    background-color: #F7F7FA;
    border-color: #C6CBDD;
    border-radius: 5px;
  }

  .mat-mdc-row td.mat-mdc-cell {
    border: none;
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .table-toolbar {
    @include mat-mdc.m2-typography-level($typography-config, 'headline-6');
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
