@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-button-toggle-checked {
    border-radius: 10px;
  }

  .mat-mdc-button-toggle-label-content {
    color: mat-mdc.m2-get-color-from-palette($secondary-palette, 500);
  }

  .mat-mdc-button-toggle-checked {
    background-color: mat-mdc.m2-get-color-from-palette($primary-palette, 100);
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mdc-label {
    font: 400 14px/19px Open Sans Semibold
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
