@use 'sass:map';
@use '@angular/material' as mat-mdc;

.mat-mdc-card {
  border-radius: 10px !important;
  border-width: 1px !important;
  border-style: solid !important;
  background-color: #FFFFFF !important;
}

.mat-mdc-card-header {
  margin-bottom: 0.5rem;
  display: inline;
  .header{
    width: 100%;
  }
}

.mat-mdc-card-content{
  font-size: 14px;
  font-family: Roboto,Helvetica Neue,sans-serif;
}

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-card.hover-card:hover {
    border-color: mat-mdc.m2-get-color-from-palette($primary-palette, 500);
    box-shadow: 0 3px 6px #00000029;
  }

  .mat-mdc-card {
    border-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 200);
    color: mat-mdc.m2-get-color-from-palette($secondary-palette, 500);
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-card {
    text-align: left;
    font-family: "Open Sans Semibold";

    .mat-mdc-card-header{

      h2{
        @include mat-mdc.m2-typography-level($typography-config, 'headline-2');
      }

      h4{
        @include mat-mdc.m2-typography-level($typography-config, 'headline-4');
      }
    }

    .mat-mdc-card-content{
      h2{
        @include mat-mdc.m2-typography-level($typography-config, 'headline-2');
      }

      h4{
        @include mat-mdc.m2-typography-level($typography-config, 'headline-4');
      }
    }

    .mat-mdc-card-footer{
      padding: 0 1rem 1rem 1rem;
    }

  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
