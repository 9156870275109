@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-button-toggle-checked {
    border-radius: 0;
  }

  .mat-mdc-button-toggle-appearance-standard .mat-mdc-button-toggle-label-content {
    padding: 0 18px !important;
  }

  .mat-mdc-button-toggle-label-content {
    color: #000000;
  }

  .mat-mdc-button-toggle-appearance-standard {
    background-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 50);
    border: solid 3px mat-mdc.m2-get-color-from-palette($secondary-palette, 50);
  }

  .mat-mdc-button-toggle-checked .mat-mdc-button-toggle-label-content {
    border-radius: 5px;
    background-color: #FFFFFF !important;
  }

  .mat-mdc-button-toggle-appearance-standard:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .mat-mdc-button-toggle-appearance-standard:last-of-type {
    background: inherit;
    border: none;
  }

  .mat-mdc-button-toggle-appearance-standard:last-of-type .mat-mdc-button-toggle-label-content {
    color: mat-mdc.m2-get-color-from-palette($secondary-palette, 300);
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-button-toggle-label-content {
    text-align: left;
    font-family: "Open Sans Semibold";
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
