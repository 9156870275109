@use 'sass:map';
@use '@angular/material' as mat-mdc;

.mat-mdc-list.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-item-content {
  padding: 0 !important;
  span{
    margin: 0 !important;
  }
}

.mat-mdc-list.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content {
  gap: 0.5rem;
}

.mat-mdc-list.mat-mdc-list-base {
  padding: 0 !important;
}

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-list-base .mat-mdc-list-item * :not(mat-icon) {
    @include mat-mdc.m2-typography-level($typography-config, 'body-2');
  }

  .mat-mdc-list-base .mat-mdc-list-item .list-item-value {
    @include mat-mdc.m2-typography-level($typography-config, 'button');
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
