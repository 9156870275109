@use 'sass:map';
@use '@angular/material' as mat-mdc;

.mdc-button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-outlined-button.mat-primary {
    border-color: mat-mdc.m2-get-color-from-palette($primary-palette, 500) !important;
  }

  .mat-mdc-raised-button:not(:disabled){
    color:white!important;
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
