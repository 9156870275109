@use 'sass:map';
@use '@angular/material' as mat-mdc;

.mat-mdc-form-field-outline {
  border-radius: 5px;

}

.mat-mdc-form-field-underline {
  display: none;

}

.mat-mdc-form-field-infix {
  display: flex !important;
  align-items: center;

}

.mat-mdc-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-form-field {
    background-color: white;
    position: relative;
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-form-field-wrapper {
    text-align: left;
    font-family: "Open Sans Semibold";
  }

  .mat-label{
    font-size: 19px;
  }
}

@mixin theme($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat-mdc.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
