@use '@angular/material' as mat-mdc;

// Icons
@use './theme/_icons.scss';

// Components - Custom themes only
@use './theme/components/_toggle-button-theme.scss' as toggle-button;
@use './theme/components/_secondary-toggle-button-theme.scss' as secondary-toggle-button;
@use './theme/components/_button-theme.scss' as button;
@use './theme/components/_toolbar-theme.scss' as toolbar;
@use './theme/components/_card-theme.scss' as card;
@use './theme/components/_form-field-theme.scss' as form-field;
@use './theme/components/_autocomplete-theme.scss' as autocomplete;
@use './theme/components/_list-theme.scss' as list;
@use './theme/components/_nav-list-theme.scss' as nav-list;
@use 'theme/components/chips-theme' as chips;
@use './theme/components/_slide-toggle-theme.scss' as slide-toggle;
@use './theme/components/_table-theme.scss' as table;
@use './theme/components/_tab-group-theme.scss' as tab-group;
@use './theme/components/_dialog-theme.scss' as dialog;

// Palettes
@use './theme/palettes/_primary-palette.scss' as primary;
@use './theme/palettes/_secondary-palette.scss' as secondary;
@use './theme/palettes/_warn-palette.scss' as warn;
@use './theme/palettes/_success-palette.scss' as success;

// Typography
@use './theme/typographies/_main-typography.scss' as typography;

$theme: mat-mdc.m2-define-light-theme((
  color: (
    primary: primary.$palette,
    accent: secondary.$palette,
    warn: warn.$palette
  ),
  typography: typography.$config,
  density: 0
));

@include mat-mdc.all-component-themes($theme);
@include mat-mdc.all-component-typographies($theme);
@include mat-mdc.core();

// Custom themes
@include toggle-button.theme($theme);
@include button.theme($theme);
@include card.theme($theme);
@include form-field.theme($theme);
@include autocomplete.theme($theme);
@include list.theme($theme);
@include nav-list.theme($theme);
@include chips.theme($theme);
@include slide-toggle.theme($theme);
@include table.theme($theme);
@include tab-group.theme($theme);
@include dialog.theme($theme);

.secondary-theme {
  @include secondary-toggle-button.theme($theme);
}

:root {
  --primary-color: #{mat-mdc.m2-get-color-from-palette(primary.$palette, 500)};
  --primary-color-200: #{mat-mdc.m2-get-color-from-palette(primary.$palette, 200)};
  --primary-color-100: #{mat-mdc.m2-get-color-from-palette(primary.$palette, 100)};

  --secondary-color-500: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 500)};
  --secondary-color-400: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 400)};
  --secondary-color-300: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 300)};
  --secondary-color-200: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 200)};
  --secondary-color-100: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 100)};
  --secondary-color-50: #{mat-mdc.m2-get-color-from-palette(secondary.$palette, 50)};

  --warn-color: #{mat-mdc.m2-get-color-from-palette(warn.$palette, 500)};
  --warn-color-50: #{mat-mdc.m2-get-color-from-palette(warn.$palette, 50)};

  --success-color: #{mat-mdc.m2-get-color-from-palette(success.$palette, 500)};

  --header-height: 5rem;
}
