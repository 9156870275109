@use 'sass:map';
@use '@angular/material' as mat-mdc;

@mixin color($theme) {
  $color-config: mat-mdc.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-mdc-dialog-content {
    .header {

      color: black;

    }

    .form {
      .mat-mdc-basic-chip {
        border-color: mat-mdc.m2-get-color-from-palette($secondary-palette, 200);
        color: mat-mdc.m2-get-color-from-palette($secondary-palette, 500);
      }

      .Mat-diver {
        border-top-color: mat-mdc.m2-get-color-from-palette($primary-palette, 500);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat-mdc.m2-get-typography-config($theme);

  .mat-mdc-dialog-content {
    .header h4 {
      @include mat-mdc.m2-typography-level($typography-config, 'headline-4');
    }

    .form {
      .form-item mat-label {
        @include mat-mdc.m2-typography-level($typography-config, 'body-2');
      }
    }

    .footer .submit-button {
      @include mat-mdc.m2-typography-level($typography-config, 'button');
    }
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
